:root {
  // The font size
  --font-size: 18px;
  @media only screen and (min-width: 768px) {
    --font-size: 15px;
  }
}

[data-theme="dark"] {

}

:root {
  // The select input variable
  --field-select-options-open-top-start: calc(100% + 30px);
  --field-select-options-open-top-end: calc(100% + 5px);
  --field-select-options-open-bottom-start: calc(100% + 30px);
  --field-select-options-open-bottom-end: calc(100% + 5px);
  --field-select-options-multiple-puce-bg: rgba(0, 0, 0, 0.5);

  // The form variables
  --form-gap: 20px;
  --form-field-gap: 10px;
  --editor-min-height: 250px;
  --editor-max-height: 500px;
}

[data-theme="dark"] {
  // The select input variable
  --field-select-options-multiple-puce-bg: rgba(255, 255, 255, 0.6);
}

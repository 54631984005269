@use "@ckmk/angular/public/scss/mixins";
@use "sass:meta";

// Tailwind inclusion
@tailwind base;
@tailwind components;
@tailwind utilities;

@include meta.load-css('@ckmk/angular');
@include meta.load-css('styles/styles');

* {
  box-sizing: border-box;
  position: relative;
  color: var(--txt-color);

  @include mixins.selectionText(var(--select-bg), var(--select-color));
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

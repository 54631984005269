// Refactored at 2024-12-01

@keyframes rotateAnimate {
  0% {
    transform-origin: center;
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes openFromBottomSelectOption {
  0% {
    opacity: 0;
    top: var(--field-select-options-open-bottom-start);
  }
  100% {
    opacity: 1;
    top: var(--field-select-options-open-bottom-end);
  }
}

@keyframes closeFromBottomSelectOption {
  0% {
    opacity: 1;
    top: var(--field-select-options-open-bottom-end);
  }
  100% {
    opacity: 0;
    top: var(--field-select-options-open-bottom-start);
  }
}

@keyframes openFromTopSelectOption {
  0% {
    opacity: 0;
    bottom: var(--field-select-options-open-top-start);
  }
  100% {
    opacity: 1;
    bottom: var(--field-select-options-open-top-end);
  }
}

@keyframes closeFromTopSelectOption {
  0% {
    opacity: 1;
    bottom: var(--field-select-options-open-top-end);
  }
  100% {
    opacity: 0;
    bottom: var(--field-select-options-open-top-start);
  }
}

@keyframes modalBackdropShow {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  50% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes modalBackdropHide {
  0% {
    visibility: visible;
    opacity: 1;
  }
  50% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes modalContentFadeShow {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  50% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes modalContentFadeHide {
  0% {
    visibility: visible;
    opacity: 1;
  }
  50% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes modalContentTopShow {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-30px);
  }
  50% {
    visibility: visible;
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes modalContentTopHide {
  0% {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    visibility: visible;
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-30px);
  }
}

@keyframes modalContentBottomShow {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translateY(30px);
  }
  50% {
    visibility: visible;
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes modalContentBottomHide {
  0% {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    visibility: visible;
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    visibility: hidden;
    opacity: 0;
    transform: translateY(30px);
  }
}

@keyframes modalContentScaleShow {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: scale(.8);
  }
  50% {
    visibility: visible;
    opacity: 0;
    transform: scale(.8);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalContentScaleHide {
  0% {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
  50% {
    visibility: visible;
    opacity: 0;
    transform: scale(.8);
  }
  100% {
    visibility: hidden;
    opacity: 0;
    transform: scale(.8);
  }
}

@use 'mixins';
@use 'classes/colors.classes';
@use 'classes/fonts.classes';
@use 'classes/alerts.classes';

// Refactored at 2024-12-01

.default-body {
  // New_Includes
  @include mixins.defaultBody;
  // New_Includes
}

.container {
  // New_Includes
  @include mixins.defaultBody;
  @include mixins.maxWidth;
  // New_Includes
}

.hidden {
  display: none !important;
}


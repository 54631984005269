// Refactored at 2024-12-01

//@use "sass:meta";
//
//// Déclaration des polices front
//@font-face {
//  font-family: 'Nunito';
//  src: url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
//}
//
//@font-face {
//  font-family: 'Roboto Condensed';
//  src: url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
//}
//
//@font-face {
//  font-family: 'Roboto';
//  src: url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
//}
//
//// Déclaration des polices Dashboard
//@font-face {
//  font-family: 'M PLUS Rounded 1c';
//  src: url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap');
//}

//// Front fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
//
//// Dashboard fonts
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap');

:root {
  --txt-color: black;

  --select-bg: black;
  --select-color: white;
}

[data-theme="dark"] {

  :root {
    --txt-color: black;

    --select-bg: black;
    --select-color: white;
  }
}

@use "@ckmk/angular/public/scss/mixins";

.front-container {
  width: 90%;
  position: relative;
  display: block;
  margin: auto;

  @include mixins.tabletView() {
    max-width: 90%;
  }

  @include mixins.computerView(1280px) {
    max-width: 80%;
  }
}

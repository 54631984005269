// Refactored at 2024-12-01

///////////////////////////////////////////////////// DEFAULT MIXINS
@mixin defaultBody {
  & {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;
  }
}

@mixin defaultColumn {
  // New_Includes
  @include defaultBody;

  @include threePoint {
    padding: 0;
  }
  // New_Includes

  & {
    display: inline-block;
  }
}

@mixin animationMixin {
  & {
    animation-delay: 0s;
    animation-duration: .3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    @content;
  }
}

@mixin active($hover: true, $focus: true, $active: true) {
  @if $active {
    &:active {
      @content
    }
  }

  @if $hover {
    &:hover {
      @content
    }
  }

  @if $focus {
    &:focus {
      @content
    }
  }
}

@mixin bgCover {
  & {
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    @content;
  }
}

@mixin threePoint {
  & {
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    @content;
  }
}

@mixin inputRemoveDefaultIcons {

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &[type=date],
  &[type=datetime-local],
  &[type=month],
  &[type=week],
  &[type=time],
  {
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }
}

@mixin respond($breakpoint, $min_width: 0, $max_width: 0) {
  @if $breakpoint ==mobile {
    @if $max_width ==0 {
      $max_width: 768px;
    }

    @if $max_width !=0 {
      @media only screen and (min-width: 0) and (max-width: $max_width) {
        & {
          @content
        }
      }
    } @else {
      @media only screen and (min-width: 0) {
        & {
          @content
        }
      }
    }
  } @else if $breakpoint ==tablet {
    @if $min_width == 0 {
      $min_width: 768px;
    }

    @if $max_width !=0 {
      @media only screen and (min-width: $min_width) and (max-width: $max_width) {
        & {
          @content
        }
      }
    } @else {
      @media only screen and (min-width: $min_width) {
        & {
          @content
        }
      }
    }
  } @else if $breakpoint ==computer {
    @if $min_width ==0 {
      $min_width: 998px;
    }

    @media only screen and (min-width: $min_width) {
      & {
        @content
      }
    }
  } @else {
    @if $min_width != 0 {
      @if $max_width !=0 {
        @media only screen and (min-width: $min_width) and (max-width: $max_width) {
          & {
            @content
          }
        }
      } @else {
        @media only screen and (min-width: $min_width) {
          & {
            @content
          }
        }
      }
    }
  }
}

@mixin respondHeight($min_height: 0, $max_height: 0) {
  @media only screen and (min-height: $min_height) and (max-height: $max_height) {
    & {
      @content
    }
  }
}

@mixin mobileView($max_width: 0) {
  @include respond(mobile, 0, $max_width) {
    @content;
  }
}

@mixin tabletView($min_width: 768px, $max_width: 0) {
  @include respond(tablet, $min_width, $max_width) {
    @content;
  }
}

@mixin computerView($min_width: 998px) {
  @include respond(computer, $min_width) {
    @content;
  }
}

@mixin maxWidth {
  & {
    display: block;
    margin: auto;
    width: 90%;
    @content;
  }

  @include tabletView {
    width: 85%;
    max-width: 960px;
    @content;
  }

  @include computerView(1200px) {
    max-width: 1150px;
    @content;
  }

  @include computerView(1600px) {
    max-width: 1500px;
    @content;
  }

  @include computerView(2000px) {
    max-width: 1700px;
    @content;
  }
}

@mixin icon($i: true, $svg: true) {
  @if $i {
    i, fa-icon {
      @content;
    }
  }

  @if $svg {
    svg, fa-icon {
      @content;

      * {
        @content;
      }
    }
  }
}

@mixin columnsWidth($count, $gap: 5px) {
  $elementWidth: 100%;

  @if $count != 1 {
    @if $count != 2 {
      $gap: calc($gap * ($count - 1));
    }

    $elementWidth: calc((100% / $count) - ($gap / $count));
  }

  & > * {
    width: $elementWidth;
  }
}

@mixin selectionText($bg, $color : white) {
  &::selection {
    background-color: $bg;
    color: $color;
  }

  * {
    &::selection {
      background-color: $bg;
      color: $color;
    }
  }
}

@mixin scrollBar(
  $scrollScrollbarThumb,
    $scrollScrollbarTrack,
    $width: 8px,
    $height: 5px,
    $radius: 50px,
) {

  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollScrollbarThumb;
    border-radius: $radius;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollScrollbarTrack;
    border-radius: $radius;
    cursor: pointer;
  }
}

@mixin rotateInfinite {
  & {
    animation: rotateAnimate 1s infinite ease-in-out;
  }
}

///////////////////////////////////////////////////// DEFAULT MIXINS

///////////////////////////////////////////////////// FONTS MIXINS
@mixin fontNunito($weight: 300) {
  & {
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-weight: $weight;
    font-style: normal;
  }
}

@mixin fontRobotoCondensed($weight: 600) {
  & {
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: $weight;
    font-style: normal;
  }
}

@mixin fontRobotoThin {
  & {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
}

@mixin fontRobotoThinItalic {
  & {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
}

@mixin fontRobotoLight {
  & {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
}

@mixin fontRobotoLightItalic {
  & {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
}

@mixin fontRobotoRegular {
  & {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
}

@mixin fontRobotoRegularItalic {
   & {
     font-family: "Roboto", sans-serif;
     font-weight: 400;
     font-style: italic;
   }
}

@mixin fontRobotoMedium {
  & {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
}

@mixin fontRobotoMediumItalic {
  & {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
}

@mixin fontRobotoBold {
  & {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
}

@mixin fontRobotoBoldItalic {
  & {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
}

@mixin fontRobotoBlack {
  & {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
}

@mixin fontRobotoBlackItalic {
  & {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
}

///////////////////////////////////////////////////// FONTS MIXINS

///////////////////////////////////////////////////// ALERT MIXINS
@mixin alert($type: info) {
  $bg: null;
  $color: null;
  $border: null;

  @if ($type == info) {
    $bg: var(--color-info-alert-bg);
    $color: var(--color-info-alert-color);
    $border: var(--color-info-alert-bdr);
  } @else if ($type == success) {
    $bg: var(--color-success-alert-bg);
    $color: var(--color-success-alert-color);
    $border: var(--color-success-alert-bdr);
  } @else if ($type == warning) {
    $bg: var(--color-warning-alert-bg);
    $color: var(--color-warning-alert-color);
    $border: var(--color-warning-alert-bdr);
  } @else if ($type == danger) {
    $bg: var(--color-danger-alert-bg);
    $color: var(--color-danger-alert-color);
    $border: var(--color-danger-alert-bdr);
  }

  // New_Includes
  @include defaultBody;

  @include selectionText(
    // Background
      $color,
      // Color
      $bg
  );

  @include icon {
    color: $color;
    margin-top: 2px;

    @include tabletView {
      margin-top: 1.5px;
    }
  }
  // New_Includes

  & {
    display: inline-flex;
    border-radius: 3px;
    cursor: context-menu;
    background-color: $bg;
    color: $color;
    border: solid 1px $border;
    margin: 10px 0;
    overflow: hidden;
    height: fit-content;
  }

  * {
    color: $color;
    margin: 0;
  }

  b {
    font-weight: bold;
    color: $color;
  }

  .alert-messages {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }

  .alert-message-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 7px;
  }

  .alert-button-remove {
    display: inline-flex;
    align-items: flex-start;
    padding: 10px;
  }

  &.alert-full-content {
    width: 100%;
  }
}

///////////////////////////////////////////////////// ALERT MIXINS

///////////////////////////////////////////////////// FORM MIXINS
@mixin containerWithGap {
  & {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    column-gap: var(--form-field-gap);
    row-gap: var(--form-field-gap);
  }
}

///////////////////////////////////////////////////// FORM MIXINS

///////////////////////////////////////////////////// DATATABLE MIXINS
@mixin datatableActionButtonContainer {

  // New_Includes
  @include defaultBody;
  // New_Includes

  & {
    display: inline-flex;
    width: 100%;
    border-bottom: solid 1px var(--datatable-actions-button-color);
  }

  // New_Includes

  @include tabletView {
    border-bottom: none;
    width: max-content;
  }
  // New_Includes

  .action-button {

    @include defaultBody;

    & {
      display: inline-flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      column-gap: var(--datatable-actions-content-gap);
      padding: var(--datatable-actions-button-padding);
    }

    span {

      // New_Includes
      @include defaultBody;

      & {

        font-size: 15px;
        font-weight: bold;
      }

      @include tabletView {
        display: none;
      }
      // New_Includes
    }

    // New_Includes

    @include icon {

      font-size: 18px;

      // New_Includes
      @include tabletView {
        font-size: 16px;
      }
      // New_Includes
    }

    @include active {
      @include icon {
        color: var(--datatable-actions-active-color);
      }
    }

    @include tabletView {
      --datatable-actions-button-padding: 10px;

      width: max-content;
    }
    // New_Includes

  }

  &:last-child {
    border-bottom: none;
  }

}

///////////////////////////////////////////////////// DATATABLE MIXINS

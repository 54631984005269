// Refactored at 2024-12-01

// Text colors
.txt-white {
  color: var(--color-white);
}

.txt-dark {
  color: var(--color-dark);
}

.txt-color {
  color: var(--color-txt);
}

.txt-primary {
  color: var(--color-primary);
}

.txt-primary-hover {
  color: var(--color-primary-hover);
}

.txt-primary-light {
  color: var(--color-primary-light);
}

.txt-danger {
  color: var(--color-danger);
}

.txt-danger-hover {
  color: var(--color-danger-hover);
}

.txt-danger-light {
  color: var(--color-danger-light);
}

.txt-warning {
  color: var(--color-warning);
}

.txt-warning-hover {
  color: var(--color-warning-hover);
}

.txt-warning-light {
  color: var(--color-warning-light);
}

.txt-success {
  color: var(--color-success);
}

.txt-success-hover {
  color: var(--color-success-hover);
}

.txt-success-light {
  color: var(--color-success-light);
}

.txt-purple {
  color: var(--color-purple);
}

.txt-purple-hover {
  color: var(--color-purple-hover);
}

.txt-purple-light {
  color: var(--color-purple-light);
}

// Text colors

// Background colors
.bg-white {
  background-color: var(--color-white);
}

.bg-dark {
  background-color: var(--color-dark);
}

.bg-primary {
  background-color: var(--color-primary);
}

.bg-primary-hover {
  background-color: var(--color-primary-hover);
}

.bg-primary-light {
  background-color: var(--color-primary-light);
}

.bg-danger {
  background-color: var(--color-danger);
}

.bg-danger-hover {
  background-color: var(--color-danger-hover);
}

.bg-danger-light {
  background-color: var(--color-danger-light);
}

.bg-warning {
  background-color: var(--color-warning);
}

.bg-warning-hover {
  background-color: var(--color-warning-hover);
}

.bg-warning-light {
  background-color: var(--color-warning-light);
}

.bg-success {
  background-color: var(--color-success);
}

.bg-success-hover {
  background-color: var(--color-success-hover);
}

.bg-success-light {
  background-color: var(--color-success-light);
}

.bg-purple {
  background-color: var(--color-purple);
}

.bg-purple-hover {
  background-color: var(--color-purple-hover);
}

.bg-purple-light {
  background-color: var(--color-purple-light);
}

// Background colors

@use '../mixins';

// Refactored at 2024-12-01

.alert-full {
  width: 100%;
}

.alert-info {
  // New_Includes
  @include mixins.defaultBody;

  @include mixins.alert(info);
  // New_Includes
}

.alert-success {
  // New_Includes
  @include mixins.defaultBody;

  @include mixins.alert(success);
  // New_Includes
}

.alert-warning {
  // New_Includes
  @include mixins.defaultBody;

  @include mixins.alert(warning);
  // New_Includes
}

.alert-danger {
  // New_Includes
  @include mixins.defaultBody;

  @include mixins.alert(danger);
  // New_Includes
}

:root {
  --datatable-container-gap: 10px;
  --datatable-content-min-width: 768px;
  --datatable-column-head-padding: 10px 15px;
  --datatable-column-body-padding: 10px 18px;
  --datatable-container-height: 55vh;
  --datatable-container-rounded: var(--dashboard-card-rounded);
  --datatable-container-padding: var(--dashboard-card-padding);
  --datatable-container-shadow: var(--dashboard-card-shadow);

  --datatable-container-bg-color: var(--dashboard-card-bgcolor);
  --datatable-header-bg-color: var(--datatable-container-bg-color);

  --datatable-row-hover-bg-color: var(--datatable-container-bg-color);
  --datatable-row-hover-shadow: rgba(0, 0, 0, .08);

  --datatable-scroll-cursor-color: transparent;
  --datatable-scroll-bg-color: transparent;
  --datatable-scroll-width: 5px;

  --datatable-table-collapse: separate;
  --datatable-table-spacing-x: 0;
  --datatable-table-spacing-y: 0;

  --datatable-column-radius: 7px;

  --datatable-actions-burger-open-action-opacity: .7;
  --datatable-actions-padding: 4px 10px;
  --datatable-actions-button-padding: 18px 10px;
  --datatable-actions-content-gap: 12px;
  --datatable-actions-input-border-color: var(--color-border);
  --datatable-actions-active-color: var(--color-primary);
  --datatable-actions-shadow-color: rgba(47, 130, 197, 0.45);
  --datatable-actions-button-color: var(--color-border);

  --datatable-loading-opacity: .3;
  --datatable-loading-label-font-size: 16px;
  --datatable-loading-rotate-size: 25px;
  --datatable-loading-content-gap: 15px;
  --datatable-loading-rotate-border-size: 3px;

  --datatable-pagination-gap: 15px;
  --datatable-pagination-page-padding: 12px;
  --datatable-pagination-page-icon-font-size: 16px;
  --datatable-pagination-page-font-size: 15px;
  --datatable-pagination-page-font-size-scale: 1.35;
  --datatable-pagination-page-disable-color: var(--dashboard-font-color);

  --datatable-count-items-font-size: 16px;

  --datatable-pagination-count-lines-font-size: 14px;
  --datatable-pagination-count-lines-margin: 0 5px;
  --datatable-pagination-count-lines-gap: 8px;
}

[data-theme="dark"] {
  --datatable-row-hover-shadow: rgba(0, 0, 0, .15);

  --datatable-actions-shadow-color: rgba(51, 122, 179, 0.35);

  --datatable-loading-opacity: .2;

}

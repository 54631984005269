:root {
  // Default variables

  // Colors
  // Unchangeable
  --color-white: white;
  --color-dark: #353535;
  --color-info-alert-bg: #bee2fd;
  --color-info-alert-color: #6a879c;
  --color-info-alert-bdr: #afd0ea;
  --color-danger-alert-bg: #ffd1d1;
  --color-danger-alert-color: #7e6262;
  --color-danger-alert-bdr: #ecc1c1;
  --color-warning-alert-bg: #ffeccd;
  --color-warning-alert-color: #9a8a70;
  --color-warning-alert-bdr: #ead8bc;
  --color-success-alert-bg: #cffbe1;
  --color-success-alert-color: #738f7f;
  --color-success-alert-bdr: #c1ead2;
  // Unchangeable
  --color-shadow: rgba(0, 0, 0, 0.15);
  --color-bg: white;
  --color-bg-hover: #efefef;
  --color-txt: #494949;
  --color-help: #a3a3a3;
  --color-placeholder: #b3b3b3;
  --color-disabled: #e4e4e4;
  --color-border: #b3b3b3;
  --color-primary: #2f82c5;
  --color-primary-hover: #2870aa;
  --color-primary-light: #67b7f8;
  --color-danger: #d13c3c;
  --color-danger-hover: #af3333;
  --color-danger-light: #f46e6e;
  --color-warning: #f37041;
  --color-warning-hover: #d36139;
  --color-warning-light: #f89c7b;
  --color-success: #43ac6c;
  --color-success-hover: #368856;
  --color-success-light: #82e1a7;
  --color-purple: #974cb1;
  --color-purple-hover: #703983;
  --color-purple-light: #be87d1;
}

[data-theme="dark"] {
  // Colors
  --color-shadow: rgba(0, 0, 0, .4);
  --color-bg: #212d43;
  --color-bg-hover: #1c263a;
  --color-txt: #bac0c4;
  --color-help: #c1c8cc;
  --color-placeholder: rgba(251, 251, 251, 0.2);
  --color-disabled: #172030;
  --color-border: rgba(179, 179, 179, 0.3);
  --color-primary: #337ab3;
  --color-primary-hover: #2c6898;
  --color-primary-light: #8ccbff;
  --color-danger: #c63b3b;
  --color-danger-hover: #c63b3b;
  --color-danger-light: #f46e6e;
  --color-warning: #ff7b4a;
  --color-warning-hover: #d6683f;
  --color-warning-light: #ffab89;
  --color-success: #52d384;
  --color-success-hover: #42a869;
  --color-success-light: #8decb1;
  --color-purple: #b85cd8;
  --color-purple-hover: #9249ac;
  --color-purple-light: #da9cef;
}
